import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/analytics';

var firebaseConfig = {
  apiKey: "AIzaSyCDMvVf-4DKnVkqfSAH7UX5lsAc-5g3q2Y",
  authDomain: "gubertech-teste.firebaseapp.com",
  projectId: "gubertech-teste",
  storageBucket: "gubertech-teste.appspot.com",
  messagingSenderId: "747043354006",
  appId: "1:747043354006:web:b77c8d2bed737da39a19b7"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED,
  merge: true
});

firebase
  .firestore()
  .enablePersistence({
    experimentalForceOwningTab: false,
    synchronizeTabs: true
  })
  .catch(err => {
    console.error("Firebase Persistence", err);
    if (err.code === "failed-precondition") {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      // ...
    } else if (err.code === "unimplemented") {
      // The current browser does not support all of the
      // features required to enable persistence
      // ...
    }
  });

firebase
  .auth()
  .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
  .catch(error => {
    console.error("Firebase Auth", error);
  });

const db = firebase.firestore();
const auth = firebase.auth();
const analytics = firebase.analytics();

export { db, auth, analytics };